<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-dw8">
        <!-- 水果呀 -->
        <div class="data" v-if="rowsData[0]">
          <h3>特码</h3>
          <ul class="custom_ul">
            <li v-for="ele in rowsData[0].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">
                <div :class="'blueball b'+ele.label">{{ele.label}}</div>
              </ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>

        </div>
        <!-- -------- -->
        <!-- 第二个data -->
        <div class="data" v-if="rowsData[1]">
          <ul class="ul1">
            <li v-for="ele in rowsData[1].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash lianTip">
                <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
 
          </ul>
        </div>
        <!-- ------- -->

      </div>
      <!-- 预设 -->
      <yushe />
      <!--  -->
      <!-- 表格  -->

      <div class="roadmap">
        
        
        <!-- 单双 -->
       

        <!--  -->
      </div>

      <!-- table  end -->
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "fantantema",
  props: [""],
  data() {
    return {
      changelongActive: "1",
      groupnames: "fantan",
      rowsData: [
        { title: "特码", arr: [] },
        { title: "两面", arr: [] },
        
      ],
     
      kjlist: [],
      chuqiusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      wuqishusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      // 球号
      lutu1: {
        arr: [[], [], [], [], []],
      },
      // 大小
      lutu2: {
        arr: [[], [], [], [], []],
      },
      // 单双
      lutu3: {
        arr: [[], [], [], [], []],
      },
      ltArr: [],
    };
  },
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
    
    yanzheng,
  },
  created() {
    // this.getHisList();
  },
  computed: {},
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title:  "特码",
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let labelArr5=[]
     for (let index =1; index < 21; index++) {
      labelArr5.push(this.checkNum(index))
      
     }
    
     let idArr5=[]
     for (let index =21809; index < 21829; index++) {
      idArr5.push(index)
      
     }
     let arr5=this.ddFilterData1(resAArr,idArr5,labelArr5)
     this.rowsData[0].arr=arr5
     let arr6=this.ddFilterData1(resAArr,[21829,21830],['特大','特小'])
     this.rowsData[1].arr=arr6
      },
    },
  },
  beforeMount() {},

  mounted() {
    //上
    this.$on("top-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="top"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("top-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="top"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //下
    this.$on("bottom-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="bottom"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("bottom-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="bottom"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //左
    this.$on("left-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="left"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("left-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="left"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //右
    this.$on("right-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="right"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("right-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="right"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
  },

  methods: {
    getKJHis() {
      (this.lutu1 = {
        arr: [[], [], [], [], []],
      }),
        // 大小
        (this.lutu2 = {
          arr: [[], [], [], [], []],
        }),
        // 单双
        (this.lutu3 = {
          arr: [[], [], [], [], []],
        });
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        pagenum: 1,
        pagecount: 50,
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]];
            let list = resObj.data;
            list.reverse()
            list.forEach((element) => {
              if(element.Opencode.includes("+")){
                let jiaArr=element.Opencode.split('+');
              element.codeArr=jiaArr[0].split(",")
              element.fan=jiaArr[1]
              }else{
                element.codeArr = element.Opencode.split(",");
              }
             let obj=this.fantangameopencode(element.codeArr)
              let sum = obj.sum;
            
              let yu='';
              if(element.Opencode.includes("+")){
                yu=element.fan
              }else{
                yu = Number(sum) % 4;
              yu = yu == 0 ? 4 : yu;
              }
           
              let dx = obj.dx;
              let ds = Number(element.fan)%2==0?'双':'单'
              // 球号
              this.initlutuData(arr1, yu);
              // 大小
              this.initlutuData(arr2, dx);
              //  单双
              this.initlutuData(arr3, ds);
            });
            this.buquanArr1(arr1, this.lutu1.arr);
            this.buquanArr1(arr2, this.lutu2.arr);
            this.buquanArr1(arr3, this.lutu3.arr);
            // console.log(this.lutu1.arr);
          } else {
            (this.lutu1 = {
              arr: [[], [], [], [], []],
            }),
              // 大小
              (this.lutu2 = {
                arr: [[], [], [], [], []],
              }),
              // 单双
              (this.lutu3 = {
                arr: [[], [], [], [], []],
              });
          }
        } else {
          (this.lutu1 = {
            arr: [[], [], [], [], []],
          }),
            // 大小
            (this.lutu2 = {
              arr: [[], [], [], [], []],
            }),
            // 单双
            (this.lutu3 = {
              arr: [[], [], [], [], []],
            });
        }
      });
    },
    getHisList() {
      this.chuqiusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      this.wuqishusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        roomeng: this.currentGame.roomeng,
        pagenum: 1,
        pagecount: 1000,
      };
      this.$http
        .post("getlotteryhis", obj)
        .then((res) => {
          if (res.Status) {
            let resObj = JSON.parse(res.Msg);
            this.kjlist = resObj.data;
            this.kjlist.reverse();
            this.kjlist.forEach((item) => {
              if(item.Opencode.includes("+")){
                let jiaArr=item.Opencode.split('+');
              item.codeArr=jiaArr[0].split(",")
              item.fan=jiaArr[1]
              }else{
                item.codeArr = item.Opencode.split(",");
              }
              let obj=this.fantangameopencode( item.codeArr)
              let sum = obj.sum;
            
              let yu='',fan=''
              if(item.Opencode.includes("+")){
               fan=item.fan
              }else{
                yu = Number(sum) % 4;
               fan = yu == 0 ? 4 : yu;
              }
          
              for (const key in this.chuqiusum) {
                if (key[3] == fan) {
                  this.chuqiusum[key]++;
                  this.wuqishusum[key] = 0;
                }
                if (key[3] != fan) {
                  this.wuqishusum[key]++;
                }
              }
            });
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    filterRowsData(index, i) {
      return this.rowsData[index].arr.filter((item, elei) => {
        return elei == i;
      });
    },
    MouseEnter(e, str) {
      this.$emit(str + "-hovered");
    },
    MouseLeave(e, str) {
      this.$emit(str + "-unhovered");
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li {
  width: 25%;
}
.listmain .data .ul1 li {
  width: 50%;
}
</style>